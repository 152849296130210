/* ---- header styles ---- */

.header {

    &:extend(.footer);

    body & {

        position: fixed;
        z-index: 100;
        color: @headerTextColor;
        left: 0;
        top: 0;
        width: 100%;
        overflow: hidden;
    }

    a {
        color: inherit;
        text-decoration: none;
    }

}