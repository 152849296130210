/* ---- site titles styles ---- */


// page title

h1 {
    &:extend(.siteTitle);
    font-weight: normal;
    text-transform: uppercase;
    text-align: center;
    .mp0();
    padding: 52px 0 6px 0;
}


.siteTitle {
    position: relative;
    display: block;
    line-height: 1.2;
    font-weight: normal;
    color: @titleMainColor;
    .fam(Marck Script);

    &--small {
        font-size: 14px;
        line-height: 1.3;
    }

    &--inline {
        position: relative;
        .inline-block();
    }

    &--middle {
        .fs(30);
        line-height: 1.3;
    }

    &--big {
        line-height: 1.07;
        .fs(unit(@v-large));
    }

    &--largest {
        .fs(unit(@largest));
    }

    &--normal {
        font-weight: normal;
    }

    &--white {
        color: @whiteColor;
    }

    &--upperCase {
        text-transform: uppercase;
    }

    &--noMP {
        margin: 0;
        padding: 0;
    }

    &--noM {
        margin: 0;
    }

}
