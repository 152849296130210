/**
    блок с ценами
 */

.prices {
    color: @greyColor;

    &__inner {
    }

    &__item {
        padding-bottom: 55px;

        .flexItemIndentWithMargin(4%, 5);

        &:nth-of-type(5n+5) {
            margin-right: 0;
        }

        @media(max-width: 1800px) {

            .flexItemIndentWithMargin(10%, 3);

            &:nth-of-type(1n+1) {
                margin-right: 10%;
            }

            &:nth-of-type(3n+3) {
                margin-right: 0;
            }

        }

    }

    &__itemTitle {
        line-height: 1;
        padding-bottom: 35px;
        white-space: nowrap;
    }

    &__itemInner {
        position: relative;
        .align-items(flex-end);
        -ms-flex-align: end;

        &:not(:last-of-type) {
            margin-bottom: 25px;
        }

        //&::before {
        //    display: block;
        //    content: '';
        //    position: absolute;
        //    bottom: .4em;
        //    left: 0;
        //    right: 0;
        //    height: 0;
        //    border-bottom: 2px dotted @greyColor;
        //    z-index: -1;
        //}

        & > div {
            background: #fff;

            &:first-of-type {
                position: relative;
                padding-right: 20px;
                overflow: hidden;
                .flex-grow(1);

                & > span {
                    background: @whiteColor;
                    position: relative;
                    z-index: 1;
                    padding-right: 3px;
                }

                &::after {
                    z-index: 0;
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    right: 0;
                    content: '............................................................................................................................................................................................................................................................................................................';
                }

            }

            &:last-of-type {
                white-space: nowrap;
                padding-left: 2px;
            }

        }
    }

}
