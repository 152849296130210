

// identation padding margins etc

@baseIndent10 : @baseVerticalGrid * 10;
@baseIndent9 : @baseVerticalGrid * 9;
@baseIndent8 : @baseVerticalGrid * 8;
@baseIndent7 : @baseVerticalGrid * 7;
@baseIndent6 : @baseVerticalGrid * 6;
@baseIndent5-5 : @baseVerticalGrid * 5.5;
@baseIndent5 : @baseVerticalGrid * 5;
@baseIndent4-5: @baseVerticalGrid * 4.5;
@baseIndent4 : @baseVerticalGrid * 4;
@baseIndent3-5 : @baseVerticalGrid * 3.5;
@baseIndent3 : @baseVerticalGrid * 3;
@baseIndent2-5 : @baseVerticalGrid * 2.5;
@baseIndent2 : @baseVerticalGrid * 2;
@baseIndent: @baseVerticalGrid;

@smallPadding: 4%;
@mediumPadding: 4.6%;
@largePadding: 6%;
@bigPadding: 9%;


.mediumPadding {

    &--t {
        padding-top: @mediumPadding;
    }

    &--b {
        padding-bottom: @mediumPadding;
    }

}

.largePadding {

    &--t {
        padding-top: @largePadding;
    }

    &--b {
        padding-bottom: @largePadding;
    }

}

.bigPadding {

    &--t {
        padding-top: @bigPadding;
    }

    &--b {
        padding-bottom: @bigPadding;
    }
    
    
    &--l {
        padding-left: @bigPadding;
    }

}

.baseIndent10 {

    &--t {
        .genMediaIndentGrid(padding-top, @baseIndent10);
    }

    &--b {
        .genMediaIndentGrid(padding-bottom, @baseIndent10);
    }

    &--l {
        .genMediaIndentGrid(padding-left, @baseIndent10);
    }

    &--r {
        .genMediaIndentGrid(padding-right, @baseIndent10);
    }

}



.baseIndent9 {

    &--t {
        .genMediaIndentGrid(padding-top, @baseIndent9);
    }

    &--b {
        .genMediaIndentGrid(padding-bottom, @baseIndent9);
    }

    &--l {
        .genMediaIndentGrid(padding-left, @baseIndent9);
    }

    &--r {
        .genMediaIndentGrid(padding-right, @baseIndent9);
    }

}

.baseIndent8 {

    &--t {
        .genMediaIndentGrid(padding-top, @baseIndent8);
    }

    &--b {
        .genMediaIndentGrid(padding-bottom, @baseIndent8);
    }

    &--l {
        .genMediaIndentGrid(padding-left, @baseIndent8);
    }

    &--r {
        .genMediaIndentGrid(padding-right, @baseIndent8);
    }


}

.baseIndent7 {

    &--t {
        .genMediaIndentGrid(padding-top, @baseIndent7);
    }

    &--b {
        .genMediaIndentGrid(padding-bottom, @baseIndent7);
    }

    &--l {
        .genMediaIndentGrid(padding-left, @baseIndent7);
    }

    &--r {
        .genMediaIndentGrid(padding-right, @baseIndent7);
    }


}


.baseIndent6 {

    &--t {
        .genMediaIndentGrid(padding-top, @baseIndent6);
    }

    &--b {
        .genMediaIndentGrid(padding-bottom, @baseIndent6);
    }

    &--l {
        .genMediaIndentGrid(padding-left, @baseIndent6);
    }

    &--r {
        .genMediaIndentGrid(padding-right, @baseIndent6);
    }

}

.baseIndent5-5 {

    &--t {
        .genMediaIndentGrid(padding-top, @baseIndent5-5);
    }

    &--b {
        .genMediaIndentGrid(padding-bottom, @baseIndent5-5);
    }

    &--l {
        .genMediaIndentGrid(padding-left, @baseIndent5-5);
    }

    &--r {
        .genMediaIndentGrid(padding-right, @baseIndent5-5);
    }

}


.baseIndent5 {

    &--t {
        .genMediaIndentGrid(padding-top, @baseIndent5);
    }

    &--b {
        .genMediaIndentGrid(padding-bottom, @baseIndent5);
    }

    &--l {
        .genMediaIndentGrid(padding-left, @baseIndent5);
    }

    &--r {
        .genMediaIndentGrid(padding-right, @baseIndent5);
    }

}

.baseIndent4-5 {

    &--t {
        .genMediaIndentGrid(padding-top, @baseIndent4-5);
    }

    &--b {
        .genMediaIndentGrid(padding-bottom, @baseIndent4-5);
    }

    &--l {
        .genMediaIndentGrid(padding-left, @baseIndent4-5);
    }

    &--r {
        .genMediaIndentGrid(padding-right, @baseIndent4-5);
    }

}

.baseIndent4 {

    &--t {
        .genMediaIndentGrid(padding-top, @baseIndent4);
    }

    &--b {
        .genMediaIndentGrid(padding-bottom, @baseIndent4);
    }

    &--l {
        .genMediaIndentGrid(padding-left, @baseIndent4);
    }

    &--r {
        .genMediaIndentGrid(padding-right, @baseIndent4);
    }

}


.baseIndent3-5 {

    &--t {
        .genMediaIndentGrid(padding-top, @baseIndent3-5);
    }

    &--b {
        .genMediaIndentGrid(padding-bottom, @baseIndent3-5);
    }

    &--l {
        .genMediaIndentGrid(padding-left, @baseIndent3-5);
    }

    &--r {
        .genMediaIndentGrid(padding-right, @baseIndent3-5);
    }

}


.baseIndent3 {

    &--t {
        .genMediaIndentGrid(padding-top, @baseIndent3);
    }

    &--b {
        .genMediaIndentGrid(padding-bottom, @baseIndent3);
    }

    &--l {
        .genMediaIndentGrid(padding-left, @baseIndent3);
    }

    &--r {
        .genMediaIndentGrid(padding-right, @baseIndent3);
    }

}


.baseIndent2-5 {

    &--t {
        .genMediaIndentGrid(padding-top, @baseIndent2-5);
    }

    &--b {
        .genMediaIndentGrid(padding-bottom, @baseIndent2-5);
    }

    &--l {
        .genMediaIndentGrid(padding-left, @baseIndent2-5);
    }

    &--r {
        .genMediaIndentGrid(padding-right, @baseIndent2-5);
    }

}


.baseIndent2 {

    &--t {
        .genMediaIndentGrid(padding-top, @baseIndent2);
    }

    &--b {
        .genMediaIndentGrid(padding-bottom, @baseIndent2);
    }

    &--l {
        .genMediaIndentGrid(padding-left, @baseIndent2);
    }

    &--r {
        .genMediaIndentGrid(padding-right, @baseIndent2);
    }

}


.baseIndent {

    &--t {
        .genMediaIndentGrid(padding-top, @baseVerticalGrid);
    }

    &--b {
        .genMediaIndentGrid(padding-bottom, @baseVerticalGrid);
    }

    &--l {
        .genMediaIndentGrid(padding-left, @baseVerticalGrid);
    }

    &--r {
        .genMediaIndentGrid(padding-right, @baseVerticalGrid);
    }

}