/* ---- social block styles ---- */

.social {

    & > a {
        color: inherit;
        text-decoration: none;
        margin: 0 8px;
        line-height: @footerHeight + 10px;
        vertical-align: top;
        .inline-block();
    }

    &--header {

        & > a {

            &:last-of-type {
                padding-top: 3px;
            }

            &:hover {

                svg {

                    path {
                        fill: darken(#6aa2f6, 10%);
                    }
                }
            }

        }

    }

    &--footer {

        padding-top: 10px;

        & > a {

            &:last-of-type {
                padding-top: 3px;
            }

            &:hover {

                svg {

                    path {
                        fill: darken(#909090, 10%);
                    }
                }
            }

        }

    }

}