
/**
    наши услуги
 */

.servises {
    position: relative;
    background: @vertyLightBlueColor;

    &__inner {
        position: relative;

        @media(min-width: 1800px) {
            padding-right: 7%;
        }

    }

    &__icons,
    &__list {
        position: relative;
    }

    &__icons {
        .flex-grow(1);
    }

    &__ico {
        position: absolute;
        left: 0;
        padding: 15px;
        background: @whiteColor;
        overflow: hidden;
        .border-radius(100%);

        & > img {
            display: block;
            .size(100%, auto);
            .border-radius(100%);
        }

        &:nth-of-type(1) {
            width: 450px;
            bottom: -15px;
            z-index: 1;
            left: -15px;
        }

        &:nth-of-type(2) {
            width: 315px;
            top: -15px;
            z-index: 2;
            left: 305px;
        }

        &:nth-of-type(3) {
            width: 380px;
            bottom: -12px;
            z-index: 3;
            left: 485px;
        }

        &:nth-of-type(4) {
            width: 265px;
            top: 55px;
            z-index: 34;
            left: 745px;
        }

    }

    &__list {
        width: 585px;
        color: @greyColor;
    }

    &__listInner {
        background: url("@{img}/servises.png") 60% 0 no-repeat;
        padding-top: 107px;
        min-height: 200px;

        ul {
            list-style-type: none;
            .mp0();

            & > li {

                position: relative;

                &:not(:last-of-type) {

                    padding-bottom: 25px;

                    &::before {
                        content: '';
                        position: absolute;
                        left: 50%;
                        bottom: 8px;
                        background: @greyColor;
                        .size(7px);
                        .trasnX(50%);
                    }

                }
            }
        }

    }

}


