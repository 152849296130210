/**
    наши специалисты
 */

.specialists {
    padding-right: 6%;
    .flex-basis(50%);

    &__inner {
        position: relative;
        padding-top: 20px;
    }

    &__item {
        .flexItemIndentWithMargin(3%, 3);
        margin-top: 30px;

        &:nth-of-type(3n+3) {
            margin-right: 0;
        }

        //@media(max-width: 1400px) {
        //
        //    .flexItemIndentWithMargin(10%, 2);
        //
        //    &:nth-of-type(n) {
        //        margin-right: 10%;
        //    }
        //
        //    &:nth-of-type(2n+2) {
        //        margin-right: 0;
        //    }
        //
        //}

    }

    &__image {
        cursor: pointer;
        margin: 0 auto;
        width: 95%;
        padding-bottom: 100%;
        overflow: hidden;
        height: 0;
        background-position: 50% 50%;
        opacity: 1;
        .bgsize(cover);
        .border-radius(50%);
        .transition(opacity .25s ease);

        &:hover {
            opacity: .8;
        }
    }

    &__title {

        .fs(19);

        & > span {
            display: block;
            font-size: @small;
            padding-top: 12px;
        }
    }

}