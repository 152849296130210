

.hoverShadows() {
    -webkit-box-shadow: 0 0 14px 0 rgba(214, 214, 214, 1);
    -moz-box-shadow: 0 0 14px 0 rgba(214, 214, 214, 1);
    box-shadow: 0 0 14px 0 rgba(214, 214, 214, 1);
}

.overlayBg {
    background-color: @defaultColor;
}


.widthImage {

    a {
        display: block;
    }

    img {
        max-width: 100%;
        vertical-align: top;
        display: block;
        height: auto !important;
    }
}

.toogleBlock {
    display: none;
}

.posRelative {
    position: relative;
}

.whiteColorAll {

    body & {

        color: @whiteColor;

        a {
            color: @whiteColor;

            &:hover {
                color: inherit;
            }
        }

    }
}

.blackColorAll {

    color: #000;

    a {
        color: #000;

        &:hover {
            color: inherit;
        }
    }
}

.cPointer {
    cursor: pointer;
}

.tUpper {
    text-transform: uppercase;
}


/* ---- cleafixe ----- */

.clear {

    &:before,
    &:after {
        content: "";
        display: table;
    }

    &:after {
        clear: both;
    }

    *zoom: 1;
}

/* ---- line height ----- */

.lineHeight(normal) {
    line-height: normal;
}

.lineHeight(small) {
    line-height: 1.35;
}

.lineHeight(small2) {
    line-height: 1.45;
}

.lineHeight(middle) {
    line-height: 1.6;
}

.lineHeight(big) {
    line-height: 2;
}

.lineBig {
    .lineHeight(big);
}

.lineSmall2 {
    .lineHeight(small2);
}

.lineSmall {
    .lineHeight(small);
}



/*  display   */

/* float otions */

.fl(@side:left) {
    .inline-block();
    position: relative;
    float: @side;
}

.bgsize(@w,@h) {
    -webkit-background-size: @w @h;
    background-size: @w @h;
    -o-background-size: @w @h;
    -moz-background-size: @w @h;
}

.bgsize(cover) {
    -webkit-background-size: cover;
    background-size: cover;
    -o-background-size: cover;
    -moz-background-size: cover;
}

.bgsize(contain) {
    -webkit-background-size: contain;
    background-size: contain;
    -o-background-size: contain;
    -moz-background-size: contain;
}

.columnsCount(@value) {
    -moz-column-count: @value;
    -webkit-column-count: @value;
    column-count: @value;
}

.columnsCount(@value) {
    -moz-column-count: @value;
    -webkit-column-count: @value;
    column-count: @value;
}

.columnGap(@value) {
    -webkit-column-gap: @value;
    -moz-column-gap: @value;
    column-gap: @value;
}

.columnNoBreak() {
    overflow: hidden; /* fix for Firefox */
    break-inside: avoid-column;
    -webkit-column-break-inside: avoid;
}


section, article {
    position: relative;
}

.mp0() {
    padding: 0;
    margin: 0
}

.fs(@size) {
    .font-size-ems(@size);
}

.row {
    display: table-row;
}

.cell {
    display: table-cell;
}

.table {
    display: table;
    width: 100%;
}

.blue() {
    border: 1px solid blue;
}

.red() {
    border: 1px solid red;
}

.green() {
    border: 1px solid green;
}

.c_align {

    body & {
        text-align: center;
    }

}

.l_align {

    body & {
        text-align: left;
    }
}

.r_align {

    body & {
        text-align: right;
    }

}

// opacity

.opacity(@val) {
    -moz-opacity: @val;
    -khtml-opacity: @val;
    opacity: @val;
}

// make other svg

.make-bg-svg(@name,@color:transparent,@repeat: no-repeat, @xPos: 50%, @yPos: 50%) {
    @svg: ~"@{img}/svg/@{name}.svg";
    background-image: url(@svg);
    background-color: @color;
    background-repeat: @repeat;
    background-position: @xPos @yPos;
}

.trasnY(@data) {
    -webkit-transform: translateY(-@data);
    -moz-transform: translateY(-@data);
    -ms-transform: translateY(-@data);
    -o-transform: translateY(-@data);
    transform: translateY(-@data);
}

.trasnY(@data, @positive) when (@positive) {
    -webkit-transform: translateY(@data);
    -moz-transform: translateY(@data);
    -ms-transform: translateY(@data);
    -o-transform: translateY(@data);
    transform: translateY(@data);
}

.trasnX(@data) {
    -webkit-transform: translateX(-@data);
    -moz-transform: translateX(-@data);
    -ms-transform: translateX(-@data);
    -o-transform: translateX(-@data);
    transform: translateX(-@data);
}

.trasnY(@data) {
    -webkit-transform: translateY(-@data);
    -moz-transform: translateY(-@data);
    -ms-transform: translateY(-@data);
    -o-transform: translateY(-@data);
    transform: translateY(-@data);
}

.translateX(@data) {
    -webkit-transform: translateX(@data);
    -moz-transform: translateX(@data);
    -ms-transform: translateX(@data);
    -o-transform: translateX(@data);
    transform: translateX(@data);
}

.trasnAll(@x,@y) {
    -webkit-transform: translate(-@x, -@y);
    -moz-transform: translate(-@x, -@y);
    -ms-transform: translate(-@x, -@y);
    -o-transform: translate(-@x, -@y);
    transform: translate(-@x, -@y);
}

// Flexbox display
// flex or inline-flex

.flex-display(@display: flex) {
    display: -webkit-box;
    display: ~"-webkit-@{display}";
    display: ~"-ms-@{display}box"; // IE10 uses -ms-flexbox
    display: ~"-ms-@{display}"; // IE11
    display: @display;
}

// The 'flex' shorthand
// - applies to: flex items
// <positive-number>, initial, auto, or none

.flex(@columns: initial) {
    -webkit-flex: @columns;
    -ms-flex: @columns;
    flex: @columns;
}

// Flex Flow Direction
// - applies to: flex containers
// row | row-reverse | column | column-reverse

.flex-direction(@direction: row) {
    -webkit-flex-direction: @direction;
    -ms-flex-direction: @direction;
    flex-direction: @direction;
}

// Flex Line Wrapping
// - applies to: flex containers
// nowrap | wrap | wrap-reverse
.flex-wrap(@wrap: nowrap) {
    -webkit-flex-wrap: @wrap;
    -ms-flex-wrap: @wrap;
    flex-wrap: @wrap;
}

// Flex Direction and Wrap
// - applies to: flex containers
// <flex-direction> || <flex-wrap>
.flex-flow(@flow) {
    -webkit-flex-flow: @flow;
    -ms-flex-flow: @flow;
    flex-flow: @flow;
}

// Display Order
// - applies to: flex items
// <integer>

.flex-order(@order: 0) {
    -webkit-box-ordinal-group: @order;
    -webkit-order: @order;
    -ms-flex-order: @order;
    -ms-order: @order;
    order: @order;
}

// Flex grow factor
// - applies to: flex items
// <number>
.flex-grow(@grow: 0) {
    -webkit-box-flex: @grow;
    -webkit-flex-grow: @grow;
    -ms-flex-grow: @grow;
    flex-grow: @grow;
    -ms-flex: @grow;
}

// Flex shrink
// - applies to: flex item shrink factor
// <number>
.flex-shrink(@shrink: 1) {
    -webkit-flex-shrink: @shrink;
    -ms-flex-shrink: @shrink;
    flex-shrink: @shrink;
}

// Flex basis
// - the initial main size of the flex item
// - applies to: flex itemsnitial main size of the flex item
// <width>

.flex-basis(@width: auto) {
    -webkit-flex-basis: @width;
    -ms-flex-basis: @width;
    flex-basis: @width;
}

// Axis Alignment
// - applies to: flex containers
// flex-start | flex-end | center | space-between | space-around

.justify-content(@justify: flex-start) {
    -webkit-justify-content: @justify;
    -ms-justify-content: @justify;
    justify-content: @justify;
}

.justify-content(space-between) {
    // ie 10
    -webkit-justify-content: space-between;
    -ms-justify-content: space-between;
    justify-content: space-between;
    -webkit-box-pack: justify;
    -moz-box-pack: justify;
    -ms-flex-pack: justify;
}

.justify-content(center) {
    // ie 10
    -webkit-justify-content: center;
    -ms-justify-content: center;
    justify-content: center;
    -webkit-box-pack: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
}

// Packing Flex Lines
// - applies to: multi-line flex containers
// flex-start | flex-end | center | space-between | space-around | stretch

.align-content(@align: stretch) {
    -webkit-box-align: @align;
    -webkit-align-content: @align;
    -ms-align-content: @align;
    align-content: @align;
}

// Cross-axis Alignment
// - applies to: flex containers
// flex-start | flex-end | center | baseline | stretch

.align-items(@align: stretch) {
    -webkit-box-align: @align;
    -webkit-align-items: @align;
    -ms-align-items: @align;
    align-items: @align;
    -ms-flex-align: @align;

    //-ms-flex-align: center; // vertical
    //-ms-flex-pack: center;
}

// Cross-axis Alignment
// - applies to: flex items
// auto | flex-start | flex-end | center | baseline | stretch

.align-self(@align: auto) {
    -ms-flex-item-align: @align;
    -webkit-align-self: @align;
    -ms-align-self: @align;
    align-self: @align;
}

// convert ems to px and back

.convertEm(@selector, @amt) when (isem(@amt)) {
        @{selector}: @amt;
}

.convertEm(@selector, @amt, @emInPx: 16) when (ispixel(@amt)) {
        @{selector}: unit((@amt / @emInPx), em);
}

// ***

.flexItemsNoWrap(@additData: {}) {

    .no-flexwrap & {

        vertical-align: top;
        margin-left: -4px;
        .inline-block();

        @additData();

    }

}

.flexItemIndentWithMargin(@margin: 0, @itemInRow: 3) {

    .flex-basis((100% - @margin * (@itemInRow - 1)) / @itemInRow);
    max-width: (100% - @margin * (@itemInRow - 1)) / @itemInRow;
    width: (100% - @margin * (@itemInRow - 1)) / @itemInRow;
    margin-right: @margin;

}

.flex-width(@width: 0) {
    .flex-basis(@width);
    min-width: @width;
    width: @width;
}


div,
a, section, article, span, img,
h1, h2, h3, h4, h5, h6 {
    -webkit-user-select: none;
    -moz-user-select: none;
    outline: 0;
    outline: none;
}

a, input, textarea, select{
    outline: none !important;
}

:focus {
    outline: none !important;
}

:active {
    outline: none !important;
}
