
// test media properties

// @propName -- необходимые свойства через пробел
// @baseIndexVar -- значение базово отступа
// @baseIndentKoef -- коефициент расщирения сжатия свойства --- @baseIndentKoef: 1.2,  .8,  .7,  .5,  .4,  .3; etc //
// @delta -- + - к базе в px
// @invert -- инвертировать знак "+" "-"
// @startIndex -- индекс разрешения с которого мы начинаем итерацию -- опционально
// @dimensDelta -- +- от нужного брейкпоинта
// alone propety padding-top etc

//@TODO @baseIndentKoef дать вохможность генерить все со своими коэфициентами сжатия

.genMediaIndentGrid(@propName, @baseVar, @delta: 0, @invert : "", @startIndex: 1, @dimensDelta: 0px, @ownKoef: @baseIndentKoef) when (isstring(@propName)) {

    .generator(@index, @propName, @baseVar, @delta, @invert, @dimensDelta) when (@index <= length(@breakPoint)) {

        @dimension: extract(@breakPoint, @index);
        @koef: extract(@baseIndentKoef, @index);
        @currentPropValue: (@baseVar + @delta) * @koef;

        @dimensionWithDelta: ~"(max-width: " (@dimensDelta + @dimension) ~")";

        & {
            @media @dimensionWithDelta {
                @{propName} : ~"@{invert}@{currentPropValue}";
            }
        }

        .generator(@index + 1, @propName, @baseVar, @delta, @invert, @dimensDelta);
    }

    .generator(@startIndex, @propName, @baseVar, @delta, @invert, @dimensDelta);
}

// mane properties padding-top margin-bottom etc

.genMediaIndentGrid(@propName, @baseVar, @delta: 0, @invert : "", @startIndex: 1, @dimensDelta: 0px, @ownKoef: @baseIndentKoef) when (default()) {

    .generator(@index: 1, @propName, @baseVar, @delta, @invert, @dimensDelta) when (@index <= length(@breakPoint)) {

        @dimension: extract(@breakPoint, @index);
        @koef: extract(@baseIndentKoef, @index);
        @currentPropValue: (@baseVar + @delta) * @koef;

        @dimensionWithDelta: ~"(max-width: " (@dimensDelta + @dimension) ~")";

        & {
            @media @dimensionWithDelta {

                .loop(@counter) when (@counter <= length(@propName)) {

                    @propAlone: extract(@propName, @counter);
                    @{propAlone} : ~"@{invert}@{currentPropValue}";

                    .loop((@counter + 1)); // next iteration
                }

                .loop(1);

            }
        }

        .generator(@index + 1, @propName, @baseVar, @delta, @invert, @dimensDelta);
    }

    .generator(@startIndex, @propName, @baseVar, @delta, @invert, @dimensDelta);
}


.genMediaProperties(@propName, @mediaListProperties, @delta: 0, @invert : "",  @useMinVal : '') when (isstring(@propName)) {

    .generator(@index: 1, @propName, @mediaListProperties) when (@index <= length(@breakPoint)) {

        @item: extract(@breakPoint, @index);
        @currentPropValue: extract(@mediaListProperties, @index) + @delta;

        & {
            @media (max-width: @item) {
                @{propName} : ~"@{invert}@{currentPropValue}";
            }
        }

        .generator(@index + 1, @propName, @mediaListProperties);
    }

    .generator(1, @propName, @mediaListProperties);
}

// колхоз еще тот переписать кхуям

.genMediaProperties(@propName, @mediaListProperties, @delta: 0, @invert : "", @useMinVal : '') when (@useMinVal = 'useMin') {

    .generator(@index: 1, @propName, @mediaListProperties, @useMinVal) when (@index <= length(@breakPoint)) {

        @item: extract(@breakPoint, @index);
        @currentPropValue: extract(@mediaListProperties, @index) + @delta;

        & {
            @media (min-width: @item) {

                .loop(@counter) when (@counter <= length(@propName)) {

                    @propAlone: extract(@propName, @counter);
                    @{propAlone} : ~"@{invert}@{currentPropValue}";

                    .loop((@counter + 1)); // next iteration
                }

                .loop(1);
            }

            & when (@index = length(@breakPoint)) {

                @media (min-width: 280px) {

                    .loop(@counter) when (@counter <= length(@propName)) {

                        @propAlone: extract(@propName, @counter);
                        @{propAlone} : ~"@{invert}@{currentPropValue}";

                        .loop((@counter + 1)); // next iteration
                    }

                    .loop(1);
                }
            }

        }

        .generator(@index + 1, @propName, @mediaListProperties, @useMinVal);
    }

    .generator(1, @propName, @mediaListProperties, @useMinVal);
}

// list of propertiens -- разделитель пробел

.genMediaProperties(@propName, @mediaListProperties, @delta: 0, @invert : "", @useMinVal: '') when (default()) {

    .generator(@index: 1, @propName, @mediaListProperties) when (@index <= length(@breakPoint)) {

        @item: extract(@breakPoint, @index);
        @currentPropValue: extract(@mediaListProperties, @index) + @delta;

        & {
            @media (max-width: @item) {

                .loop(@counter) when (@counter <= length(@propName)) {

                    @propAlone: extract(@propName, @counter);
                    @{propAlone} : ~"@{invert}@{currentPropValue}";

                    .loop((@counter + 1)); // next iteration
                }

                .loop(1);
            }
        }

        .generator(@index + 1, @propName, @mediaListProperties);
    }

    .generator(1, @propName, @mediaListProperties);
}