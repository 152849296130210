/** --------стики футер flex ------ **/

.mainContentWrapper {
    position: relative;
    .flex-display();
    .flex-direction(column);
    -webkit-box-orient: vertical;
    min-height: 100%;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .mainContentWrapper {
        height: 100%;
    }
}

.content {
    flex: 1 0 auto;
    -webkit-box-flex: 1.0;
    -moz-box-flex: 1.0;
    -ms-flex: 1 0 auto;
}

.footer {
    flex: 0 0 auto;
    -webkit-box-flex: 0.0;
    -moz-box-flex: 0.0;
    -ms-flex: 0 0 auto;
    .flex-shrink(0);
}

body {
    height: 100%;
}



/** --------стики футер flex ------ **/

//.mainContentWrapper {
//    position: relative;
//    .flex-display();
//    .flex-direction(column);
//    -webkit-box-orient: vertical;
//    -moz-box-orient: vertical;
//    min-height: 100%;
//}
//
////body {
////    height: 100%;
////}
//
//@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
//
//    .mainContentWrapper {
//        height: 100%;
//    }
//
//    //.content {
//    //    min-height: 100vh;
//    //}
//}
//
//.content {
//    flex: 1 0 auto;
//    -webkit-box-flex: 1.0;
//    -moz-box-flex: 1.0;
//    -ms-flex: 1 0 auto;
//    .flex-shrink(1);
//}
//
//.footer {
//    flex: 0 0 auto;
//    -webkit-box-flex: 0.0;
//    -moz-box-flex: 0.0;
//    -ms-flex: 0 0 auto;
//    .flex-shrink(0);
//}
//
//body {
//    height: 100%;
//}



