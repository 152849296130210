
/* ---- flexie block styles ---- */

.flexie {

    .flex-display();

    &--justify {
        .justify-content(space-between);
        -webkit-box-pack: justify;
    }

    &--justifyAround {
        .justify-content(space-around);
        -webkit-box-pack: center; // костыль
    }

    &--justifyCenter {
        .justify-content(center);
        -ms-flex-pack: center;
        -webkit-box-pack: center;
    }

    &--center {
        .align-items(center);
    }

    &--relative {
        position: relative;
    }

    &--h100 {
        height: 100%;
    }

    &--inline {
        width: 100%;
        position: relative;

        @media(min-width: 768px) {
            .flex-wrap(nowrap);
            .flex-direction(row);
            .flex-display(inline-flex);
            .justify-content(flex-start);
        }
    }

    &--column {
        .flex-direction(column);
        -webkit-box-orient: vertical;
        -moz-box-orient: vertical;
    }

    &--wrap {

        .flex-wrap(wrap);

        .no-flexwrap & {
            display: block;
        }

    }

    &__item {

        &--inline {
            max-width: 50%;
            .flex-basis(50%);
            -ms-flex: 1; // ie width bug
        }

        position: relative;
    }

    &--alignSelfCenter {
        .align-self(center);
    }

    &--alignSelfBottom {
        .align-self(flex-end);
    }

    &--half {
        width: 50%;
        max-width: 50%;
        .flex-basis(50%);
    }

    &--w1-3 {
        width: 100% / 3;
        max-width: 100% / 3;
        .flex-basis(100% / 3);
    }

    &--w1-4 {
        width: 100% / 4;
        max-width: 100% / 4;
        .flex-basis(100% / 4);
    }

    &--noshrink {
        .flex-shrink(0);
    }

}
