/* ---- красивые Ol ul список  styles ---- */

.beautyList {

    list-style-type: none;
    position: relative;
    display: block;

    li {
        display: block;
        position: relative;
        padding-left: 24px + @baseVerticalGrid;
        padding-bottom: 1em;

        &:last-of-type {
            margin-bottom: 0;
        }

    }

}