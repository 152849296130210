

@font-face {
    font-family: 'HeliosC-Bold';
    src: url('fonts/HeliosC-Bold.eot');
    src: url('fonts/HeliosC-Bold.woff2') format('woff2'),
    url('fonts/HeliosC-Bold.woff') format('woff'),
    url('fonts/HeliosC-Bold.ttf') format('truetype'),
    url('fonts/HeliosC-Bold.svg#HeliosC-Bold') format('svg'),
    url('fonts/HeliosC-Bold.eot?#iefix') format('embedded-opentype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'HeliosC';
    src: url('fonts/HeliosC.eot');
    src: url('fonts/HeliosC.woff2') format('woff2'),
    url('fonts/HeliosC.woff') format('woff'),
    url('fonts/HeliosC.ttf') format('truetype'),
    url('fonts/HeliosC.svg#HeliosC') format('svg'),
    url('fonts/HeliosC.eot?#iefix') format('embedded-opentype');
    font-weight: normal;
    font-style: normal;
}


@font-face {
    font-family: 'Marck Script';
    src: url('fonts/Marck Script.eot');
    src: url('fonts/Marck Script.woff2') format('woff2'),
    url('fonts/Marck Script.woff') format('woff'),
    url('fonts/Marck Script.ttf') format('truetype'),
    url('fonts/Marck Script.svg#Marck Script') format('svg'),
    url('fonts/Marck Script.eot?#iefix') format('embedded-opentype');
    font-weight: normal;
    font-style: normal;
}


.fam(heliosC) {
    font-family: 'HeliosC', sans-serif;
}

.fam(heliosCBold) {
    font-family: 'HeliosC-Bold', sans-serif;
}

.fam(Marck Script) {
    font-family: 'Marck Script', sans-serif;
}

@small: 15px;
@medium: 16.5px;
@bigest: 17.42px;
@big: 28px;
@large: 31px;
@largest: 51px;
@v-large: 58px;