/* ---- nav block styles ---- */

.navigation {
    position: relative;
    display: block;
    font-size: @medium;
    .flex-grow(1);

    & > ul {
        list-style-type: none;
        .mp0();

        & > li {
            position: relative;
            cursor: pointer;
            .inline-block();

            &:hover {

                color: @whiteColor;

                &::after {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: -20px;
                    right: -20px;
                    bottom: 0;
                    z-index: -1;
                    background-color: @lightBlue;
                }

            }

            &:not(:last-of-type) {
                margin-right: 50px / @siteMaxWrapper * 100%;
            }

            &:not(:first-of-type) {
                margin-left: -4px;
            }

        }

    }

    &__inner {
        position: relative;
    }
}