
#adc {

    .init() {

        /*------ main less ------ */

        @import (reference) "mixins/all";


        //////
        // MORMALIZE ALL //
        //////

        .normalize();

        ///////////////
        // INIT PARAMS //
        /////////////////

        @import (less) "init/init.less";

        @import "mixins/site/media";
        @import "mixins/site/indents";
        @import "mixins/site/mixins";

        //////
        // PLACEHOLDER  //
        //////

        .placeholder(@selector) when (@selector) {

            @{selector}::-webkit-input-placeholder {
                color: red;
            }

            @{selector}::-moz-placeholder {
                color: red;
            }

            @{selector}:-moz-placeholder {
                color: red;
            }

            @{selector}:-ms-input-placeholder {
                color: red;
            }

            @{selector} input[placeholder] {
                text-overflow: ellipsis;
            }

            @{selector} input::-moz-placeholder {
                text-overflow: ellipsis;
            }

            @{selector} input:-moz-placeholder {
                text-overflow: ellipsis;
            }

            @{selector} input:-ms-input-placeholder {
                text-overflow: ellipsis;
            }

            @{selector}:focus::-webkit-input-placeholder {
                color: transparent
            }

            @{selector}:focus::-moz-placeholder {
                color: transparent
            }

            @{selector}:focus:-moz-placeholder {
                color: transparent
            }

            @{selector}:focus:-ms-input-placeholder {
                color: transparent
            }

        }

        .placeholder(@selector) when (default()) {

            ::-webkit-input-placeholder {
                color: @searchColor;
                opacity: 1 !important;
            }

            ::-moz-placeholder {
                color: @searchColor;
                opacity: 1 !important;

            }

            :-moz-placeholder {
                color: @searchColor;
                opacity: 1 !important;
            }

            :-ms-input-placeholder {
                color: @searchColor;
                opacity: 1 !important;
            }

            input[placeholder] {
                text-overflow: ellipsis;
                opacity: 1 !important;
            }

            input::-moz-placeholder {
                text-overflow: ellipsis;
                opacity: 1 !important;
            }

            input:-moz-placeholder {
                text-overflow: ellipsis;
                opacity: 1 !important;
            }

            input:-ms-input-placeholder {
                text-overflow: ellipsis;
                opacity: 1 !important;
            }

            :focus::-webkit-input-placeholder {
                color: transparent;
            }

            :focus::-moz-placeholder {
                color: transparent;
            }

            :focus:-moz-placeholder {
                color: transparent;
            }

            :focus:-ms-input-placeholder {
                color: transparent;
            }

        }

        input::-webkit-contacts-auto-fill-button,
        input::-webkit-credentials-auto-fill-button {
            visibility: hidden;
            pointer-events: none;
            position: absolute;
            right: 0;
        }

        input::-ms-clear {
            display: none;
        }

        input::-ms-reveal {
            display: none;
        }

        html * {
            .box-sizing(border-box);

            //image-rendering: optimizeSpeed;
            //image-rendering: -moz-crisp-edges;
            //image-rendering: -o-crisp-edges;
            //image-rendering: -webkit-optimize-contrast;
            //image-rendering: optimize-contrast;
            //-ms-interpolation-mode: nearest-neighbor;

            //-webkit-font-smoothing: antialiased;
            //text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
        }

        menu, ol, ul {
            padding: 0 0 0 1.5em;
        }

        input, textarea, select {
            -webkit-appearance: none;
            border-radius: 0;
            -webkit-border-radius: 0;
        }

        * {
            -webkit-tap-highlight-color: transparent;
        }

        div, a, section, article, span, img, h1, h2, h3, h4, h5, h6 {
            -webkit-user-select: none;
            -moz-user-select: none;
        }

        /* ---- setup ---- */

        html {
            position: relative;
            font-weight: normal;
            color: @defaultColor;
            background: @bodyBgColor;
            height: 100%;
        }

        body {
            .fam(heliosC);
            .genMediaProperties(~"font-size", @fonts);
            .lineHeight(middle);
            min-width: @siteMinWidth;
            font-size: ~"@{base-font-size}px";
            position: relative;
            z-index: 1;
        }

        .mainContentWrapper {
            position: relative;
        }

        a {
            text-decoration: underline;
            outline: 0;
            color: @linkColor;

            &:hover {
                text-decoration: none;
                color: @linkHover;
            }
        }

        strong, b {
            font-weight: normal;
            .fam(heliosCBold);
        }

        h1, h2, h3, h4, h5, h6 {
        }


        /* ---- site wraper ----- */

        .wrapper {
            width: 100%;
            position: relative;
            margin: 0 auto;
            padding-left: 3.2%;
            padding-right: 3.2%;
        }

        .baseWrapper {
            position: relative;
            max-width: @siteMaxWrapper;
            margin-left: auto;
            margin-right: auto;

            @media(max-width: @siteMaxWrapperFold) {
                margin-left: 20px;
                margin-right: 20px;
            }

        }

        // --------------- SUMMARY PAGE  BUILD ------------ ///

        /* ---- header ---- */

        @import (less) "blocks/header/header.less";

        /* ---- main ---- */

        @import (less) "layouts/h100_flexie.less";

        /* ---- blocks ---- */

        @import (less) "blocks/button/button.less";
        @import (less) "blocks/topBox/topBox.less";

        @import (less) "blocks/beautyList/beautyList.less";
        @import (less) "blocks/swiper/swiper.less";

        @import (less) "blocks/flexie/flexie.less";
        @import (less) "blocks/logo/logo.less";

        @import (less) "blocks/navigation/navigation.less";
        @import (less) "blocks/social/social.less";

        @import (less) "blocks/siteTitle/siteTitle.less";
        @import (less) "blocks/mainSection/mainSection.less";

        @import (less) "blocks/cliniksBox/cliniksBox.less";
        @import (less) "blocks/premsBox/premsBox.less";
        @import (less) "blocks/servises/servises.less";
        @import (less) "blocks/prices/prices.less";

        @import (less) "blocks/appointment/appointment.less";
        @import (less) "blocks/specialists/specialists.less";

        @import (less) "blocks/map/map.less";
        @import (less) "blocks/forms/forms.less";

        @import (less) "blocks/overlay/overlay.less";

        @import (less) "blocks/indexSliders/indexSliders.less";

        /* ---- other pages ---- */

        @import (less) "blocks/textSection/textSection.less";


        /* ---- footer ---- */

        @import (less) "blocks/footer/footer.less";

        /* ---- additional styles  ---- */

        @import (less) "blocks/_additionalStylesAndBlock/_additionalStylesAndBlock.less";

    }
}

#adc > .init();
