/* ----доп стили баги фиксы все писать сюда block styles ---- */


.beforeMapBox {

    overflow: hidden;

    &__inner {

    }

}

// ** stackble tables

.stacktable {
    width: 100%;

    &.small-only {

        tr {

            &:first-of-type {

                td {

                    &:first-of-type {
                    }

                    &:last-of-type {
                        color: @defaultColor;
                    }
                }
            }

        }

    }
}

.st-head-row {
    padding-top: 1em;
}

.st-head-row.st-head-row-main {
    //font-size: 1.5em;
    padding-top: 0;
}

.st-key {
    width: 49% !important;
    text-align: right;
    padding-right: 1%;
}

.st-val {
    width: 49% !important;
    padding-left: 1%;
}

/* RESPONSIVE EXAMPLE */

.stacktable.large-only {
    display: table;
}

.stacktable.small-only {
    display: none;
}

@media (max-width: 998px) {

    .stacktable.large-only {
        display: none;
    }

    .stacktable.small-only {
        display: table;
    }
}

