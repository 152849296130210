/**
      записаться на прием
 */

.appointment {

    position: relative;
    .flex-basis(50%);

    &::after,
    &::before {
        content: '';
        position: absolute;
        left: 0;
        right: -4000px;
        height: 60px;
        background: @whiteColor;
    }

    &::before {
        top: 0;
    }

    &::after {
        bottom: 0;
    }

    &__wrapper {
        padding: 60px 0;
    }

    &__inner {
        position: relative;
        padding-left: 17%;
        padding-right: 8%;
    }

}