
/* ---- mainSection styles ---- */

.mainSection {
    position: relative;

    &__inner {
        position: relative;
    }

    &__inner {
    }

    &__aside,
    &__content {
        position: relative;
    }

    &__aside {
    }

    &__content {
    }

}