
/**
    преимущества
 */

.premsBox {
    //margin-top: -60px;
    position: relative;
    z-index: 3;

    //.blueGradient();
    
    //&::after {
    //    content: '';
    //    top: 0;
    //    bottom: 0;
    //    position: absolute;
    //    z-index: -1;
    //    left: 0;
    //    right: -5000px;
    //    //background: red;
    //    //.blueGradient();
    //}

    &__items {
        position: relative;
        padding-left: 17%;
        padding-right: 8%;
    }

    &__item {
        margin-top: 56px;
        .flexItemIndentWithMargin(10%);
        .fs(unit(@medium));

        &:nth-of-type(3n+3) {
            margin-right: 0;
        }
    }

}
