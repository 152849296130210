//////
// BLUR RADIUS FOR OVERLAY  //
//////

@blurFilterRadius: 10px;

//////
// SIZES //
//////

@headerHeight: 66px;
@footerHeight: @headerHeight;

//////
// GRADIENTS //
//////

.blueGradient {
    background: -moz-linear-gradient(0deg, #04aaef 0%, #036db3 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, 0deg, color-stop(0%, #04aaef), color-stop(100%, #036db3)); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(0deg, #04aaef 0%, #036db3 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(0deg, #04aaef 0%, #036db3 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(0deg, #04aaef 0%, #036db3 100%); /* IE10+ */
    background: linear-gradient(90deg, #04aaef 0%, #036db3 100%); /* W3C */
}

.specialistsGradient {
    background: -moz-linear-gradient(0deg, #04aaef 0%, #036db3 50%, #f19645 50%, #eb7335 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, 0deg, color-stop(0%, #04aaef), color-stop(50%, #036db3), color-stop(50%, #f19645), color-stop(100%, #eb7335)); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(0deg, #04aaef 0%, #036db3 50%, #f19645 50%, #eb7335 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(0deg, #04aaef 0%, #036db3 50%, #f19645 50%, #eb7335 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(0deg, #04aaef 0%, #036db3 50%, #f19645 50%, #eb7335 100%); /* IE10+ */
    background: linear-gradient(90deg, #04aaef 0%, #036db3 50%, #f19645 50%, #eb7335 100%); /* W3C */
}

.indexSlidersAfterGradient {
    background: -moz-linear-gradient(0deg, transparent 0%, transparent 50%, #04aaef 50%, #036db3 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, 0deg, color-stop(0%, transparent), color-stop(50%, transparent), color-stop(50%, #04aaef), color-stop(100%, #036db3)); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(0deg, transparent 0%, transparent 50%, #04aaef 50%, #036db3 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(0deg, transparent 0%, transparent 50%, #04aaef 50%, #036db3 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(0deg, transparent 0%, transparent 50%, #04aaef 50%, #036db3 100%); /* IE10+ */
    background: linear-gradient(90deg, transparent 0%, transparent 50%, #04aaef 50%, #036db3 100%); /* W3C */
}