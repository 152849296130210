/* ---- main buttons styles ---- */

.button {
    .mp0();
    text-align: center;
    padding: 0 30px;
    cursor: pointer;
    background: transparent;
    text-decoration: none;
    font-size: @small;
    height: 54px;
    line-height: 54px - 3 * 2 - 1px;
    border: 3px solid @buttonOutlineColor;
    color: @buttonOutlineColor;
    margin-top: 40px;
    min-width: 220px;
    .transition(all .15s ease-in-out);
    .inline-block();

    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px transparent;
    position: relative;
    overflow: hidden;
    -webkit-transition-property: color;
    transition-property: color;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;

    &::before {
        content: "";
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: @buttonOutlineColor;
        .border-radius(100%);
        -webkit-transform: scale(0);
        transform: scale(0);
        -webkit-transition-property: transform;
        transition-property: transform;
        -webkit-transition-duration: 0.3s;
        transition-duration: 0.3s;
        -webkit-transition-timing-function: ease-out;
        transition-timing-function: ease-out;
    }

    &:hover,
    &:focus,
    &:active {
        color: white;
    }

    &:hover::before,
    &:focus::before,
    &:active::before {
        -webkit-transform: scale(3);
        transform: scale(3);
        color: @whiteColor;
    }

    &--white {
        color: @whiteColor;
        border-color: @whiteColor;
        .transition(all .25s ease-in-out);

        &::before {
            display: none;
        }

        &:hover,
        &:focus,
        &:active {
            -webkit-transform: none;
            transform: none;
            background: @whiteColor;
            color: @buttonOutlineColor;
        }

    }

    input[type=button],
    input[type=submit] {
        background: none;
        border: none;
        .mp0();
    }

    &--full {
        display: block;
    }

    a {
        color: inherit;
        text-decoration: none;
    }

    &--nopadLR {
        padding-left: 0;
        padding-right: 0;
    }

    &--nomargLR {
        margin-left: 0;
        margin-right: 0;
    }

    &--noMinWidth {
        min-width: 0;
    }

}