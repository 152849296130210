/* ---- site logo block styles ---- */

.logo {

    img {
        display: block;
        position: relative;
        margin-left: -37px;
        margin-top: -60px;
    }

    a {
        display: block;
        height: 100%;
        width: 100%;
    }

}
