/** --------map styles google  ------ **/

.mapBox {
    position: relative;

    &__map {
        padding-bottom: 620 / 1920 * 100%;
        min-height: 380px;
        height: 0;
        position: relative;
        z-index: 1;
    }

    &__address {
        background: @whiteColor;
        bottom: 0;
        position: absolute;
        z-index: 2;
        top: 30%;
        right: 6%;
        padding: 35px 40px;
        width: 560px;
        max-width: 90%;

        a {

            color: inherit;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }

        }

    }

    &__title {
        .fs(unit(@large));
    }

    &__button {
        cursor: pointer;
        background: @lightBlue;
        padding: 2px 15px;
        margin-top: 20px;
        .transition(background .25s ease);
        .border-radius(20px);
        .inline-block();

        &:hover {
            background: lighten(@lightBlue, 10%);
        }
    }

}
