/* ----слайдеры на главной странице block styles ---- */

.indexSliders {

    position: relative;
    z-index: 2;

    &__item {
        position: relative;
        height: 0;
        padding-bottom: 610 / 1920 * 100%;
        background-position: 50% 50%;
        min-height: 550px;
        .bgsize(cover);
    }

    &__titleWrapper {
        width: 100%;
        max-height: 90%;
        padding-bottom: 40px;
        .lineHeight(small);
    }

    &--after {
        z-index: 3;
        margin-top: -60px;
        .indexSlidersAfterGradient();
    }

}