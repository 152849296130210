
// o клинике

.cliniksBox {

    position: relative;

    &__inner {
        padding-top: 60px;
    }

    &__about {
        width: 60%;
        margin: 0 auto;
        padding-top: 30px;
    }

}